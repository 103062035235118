<template>

<b-card no-body>
  <template v-if="$route.query.detail">
    <div v-if="isOpen">
        <img :src="$parent.uploader(srcFiles)" alt="" class="previewImage" srcset="">
        <a href="javascript:;" @click="closePopup">
        <i class="icon-cross closeImage"></i>
        </a>
    </div>
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">Detail Laporan Kunjungan</h5>
          </b-col>    
        </b-row>
      </b-card-header>
      <b-card-body>
        <div class="table-responsive">
            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td width="50%">
                            <div class="result_tab">
                                <h4>Nama Debitur</h4>
                                <p>{{row.lk_debitur_name}}</p>
                            </div>
                        </td>
                        <td width="50%">
                            <div class="result_tab">
                                <h4>Jenis Kunjungan</h4>
                                <p>
                                    <span v-if="row.lk_type == 'V'" class="badge badge-flat border-info text-info-600">Visit</span>
                                    <span v-else-if="row.lk_type == 'S'" class="badge badge-info">Survey</span>
                                    <span v-else>-</span>                                
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Tgl. Kunjungan</h4>
                                <p>{{row.lk_date | moment("DD MMMM YYYY")}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Lokasi yang dikunjungi (Alamat)</h4>
                                <p>{{row.lk_location || "-"}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Tujuan Kunjungan</h4>
                                <p>{{row.lk_tujuan_kunjungan}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Terkait Pinjaman</h4>
                                <p v-if="row.lk_loan == 0">Tidak Terkait Pinjaman</p>
                                <p v-else>{{row.loan_text||"-"}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Contact Person</h4>
                                <p>{{row.lk_cp || "-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>No. Contact Person</h4>
                                <p>{{row.lk_no_cp || "-"}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Hasil Kunjungan</h4>
                                <p>{{row.lk_hasil_kunjungan || "-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Follow-Up</h4>
                                <p>{{row.lk_follow_up || "-"}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Surveyor/Visitor</h4>
                                <p>{{row.visitter || "-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Bertemu Dengan</h4>
                                <p>{{row.lk_bertemu_dengan || "-"}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Status</h4>
                                    <span v-if="row.lk_status=='I'" class="badge badge-warning">Incomplete</span>
                                    <span v-else class="badge badge-success">Completed</span>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="row.lk_lat && row.lk_long">
                        <td colspan="2">
                            <div class="result_tab">
                                <h4>Lokasi Kunjungan</h4>
                                    <l-map
                                        v-if="showMap"
                                        :zoom="zoom"
                                        :center="center"
                                        :options="mapOptions"
                                        style="height: 500px"
                                        @update:center="centerUpdate"
                                        @update:zoom="zoomUpdate"
                                        >
                                        <l-tile-layer
                                            :url="url"
                                            :attribution="attribution"
                                        />

                                        <l-marker :lat-lng="withTooltip" :icon="icon">
                                           <l-tooltip :options="{ permanent: true, interactive: true }">
                                            <div>
                                                Lokasi Kunjungan
                                            </div>
                                            </l-tooltip>
                                        </l-marker>
                                    </l-map>  
                            </div>
                        </td>
                    </tr>
                    <template v-if="($parent.dataDok||[]).length">
                        <tr>
                            <td colspan="2" class="table-secondary">
                                <span class="mb-0 font-weight-semibold">Dokumen Kunjungan</span>
                            </td>
                        </tr>
                        <template v-for="(v,k) in $parent.dataChunk">
                            <div class="display-c" :key="k">
                                <tr>
                                    <td v-for="(v2,k2) in $parent.dataChunk[k]" :key="k2">
                                        <div class="result_tab">
                                            <h4>Dokumen #{{v2.keyID}}</h4>
                                            <a class="btn_view mr-1"><i class="icon-file-text2"></i></a>
                                            <div class="upload_wrapper_pict" @click="openPopup(v2.lkd_file)">
                                                <div class="file-upload_pict">
                                                    <label for="upload" class="file-upload__label_pict">
                                                        <i class="icon-eye"></i> Preview file
                                                    </label>
                                                    <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="result_tab mt-2">
                                            <h4>Remarks</h4>
                                            <p>{{v2.lkd_remarks||"-"}}</p>
                                        </div>
                                    </td>
                                </tr>
                            </div>
                        </template>   
                    </template>
                </tbody>
            </table>
        </div>
      </b-card-body>
  </template>

  <template v-else> 
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit(doSubmit())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div class="form-group">
            <b-form-checkbox
                v-model="row.lk_debitur_existed"
                name="checkbox-1"
                value="Y"
                unchecked-value="N"
                @change="changeDebitur"
                >
                Kunjungan untuk debitur terdaftar
            </b-form-checkbox>
        </div>
        <div class="row">
            <template v-if="row.lk_debitur_existed == 'N'">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Tipe Debitur<small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Tipe Debitur" v-model="row.lk_jenis_debitur" :options="Config.mr.JenisDebitur" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Tipe Debitur" 
                            v-model="row.lk_jenis_debitur" 
                            :rules="{required:1}"
                        />
                    </div>
                </div>                    
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Nama Debitur<small class="txt_mandatory">*</small></label>
                        <input type="text" v-model="row.lk_debitur_name" @keyup="$parent.removeWildChar2"
                        @input="$parent.removeMultiSpace2($event,'mfp_name')"
                        @keydown="$parent.removeWildChar2" class="form-control" placeholder="e.g. Ahmad Yahya">
                        <VValidate 
                            name="Nama Debitur" 
                            v-model="row.lk_debitur_name" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 128}"
                        />
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Tipe Debitur<small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Tipe Debitur" v-model="row.lk_jenis_debitur" :options="Config.mr.JenisDebitur" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Tipe Debitur" 
                            v-model="row.lk_jenis_debitur" 
                            :rules="{required:1}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Nama Debitur<small class="txt_mandatory">*</small></label>
                        <v-select @search="searchDebitur($event)" @open="clearDebitur" v-model="row.lk_debitur_name" placeholder="Pilih Debitur" :disabled="row.lk_jenis_debitur ? false : true" :options="$parent.mrDebitur" label="text" :clearable="true" :reduce="v=>v.valuestring">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching && search.length > 2">
                                   Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <span v-else style="opacity: 0.5">Input 3 karakter untuk mencari Debitur.</span>
                            </template>
                        </v-select>
                        <VValidate 
                            name="Nama Debitur" 
                            v-model="row.lk_debitur_name" 
                            :rules="{required:1}"
                        />          
                    </div>
                </div>
            </template>
            
            <div class="col-md-4">
                <div class="form-group">
                    <label>Tgl. Kunjungan<small class="txt_mandatory">*</small></label>
                    <div class="input-group mb-3">
                        <datepicker input-class="form-control transparent" placeholder="Pilih Tanggal Kunjungan"
                        class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.lk_date"
                        >
                        </datepicker>
                        <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                    </div>
                    <VValidate 
                        name="Tgl. Kunjungan" 
                        v-model="row.lk_date" 
                        :rules="{required:1}"
                    />  
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label for="">Tujuan Kunjungan<small class="txt_mandatory">*</small></label>
                    <input type="text" v-model="row.lk_tujuan_kunjungan" class="form-control" placeholder="e.g. Follow-Up kunjungan tanggal 16">
                    <VValidate 
                        name="Tujuan Kunjungan" 
                        v-model="row.lk_tujuan_kunjungan" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 128}"
                    />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="">Terkait Pinjaman<small class="txt_mandatory">*</small></label>
                    <v-select placeholder="Pinjaman" @open="searchLoan" :disabled="!row.lk_debitur_name" v-model="row.lk_loan" :options="$parent.dataLoan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="">Jenis Kunjungan<small class="txt_mandatory">*</small></label>
                    <v-select placeholder="Jenis Kunjungan" v-model="row.lk_type" :options="Config.mr.JenisLaporan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="">Lokasi yang dikunjungi (Alamat)<small class="txt_mandatory">*</small></label>
                    <textarea name="" id="" cols="30" rows="6" @input="$parent.removeMultiSpace2($event,'lk_location')" v-model="row.lk_location"  class="form-control" placeholder="e.g. Jl Harapan No. 2"></textarea>
                    <VValidate 
                        name="Lokasi Yang Dikunjungi" 
                        v-model="row.lk_location" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 256}"
                    />
                </div>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Tipe Lokasi Kunjungan<small class="txt_mandatory">*</small></label>
                            <v-select placeholder="Tipe Lokasi Kunjungan" v-model="row.lk_type_location" :options="Config.mr.TipeLokasi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            <VValidate 
                                name="Tipe Lokasi Kunjungan" 
                                v-model="row.lk_type_location" 
                                :rules="{required:1}"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Contact Person<small class="txt_mandatory">*</small></label>
                            <input type="text" class="form-control" v-model="row.lk_cp" placeholder="e.g. Budianto">
                            <VValidate 
                                name="Contact Person" 
                                v-model="row.lk_cp" 
                                :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1}"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">No. Contact Person</label>
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text">+62</span></div>
                                <input v-model="row.lk_no_cp" type="tel" class="form-control" placeholder="e.g. 85858121712">
                            </div>
                            <VValidate 
                                name="Mobile Number" 
                                v-model="row.lk_no_cp" 
                                :rules="{regex:/^[0-9\+]+$/}" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 col-lg-5">
                <div class="form-group">
                    <label for="">Hasil Kunjungan</label>
                    <textarea v-model="row.lk_hasil_kunjungan" name="" id="" cols="30" rows="6" class="form-control" placeholder="e.g. calon debitur setuju untuk melakukan pinjaman"></textarea>
                    
                    <VValidate 
                        name="Hasil Kunjungan" 
                        v-model="row.lk_hasil_kunjungan" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 256}"
                    />
                </div>
            </div>
            <div class="col-md-8 col-lg-5">
                <div class="form-group">
                    <label for="">Follow-Up</label>
                    <textarea v-model="row.lk_follow_up" name="" id="" cols="30" rows="6" class="form-control" placeholder="e.g. calon debitur setuju untuk melakukan pinjaman"></textarea>
                    
                    <VValidate 
                        name="Hasil Kunjungan" 
                        v-model="row.lk_follow_up" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 256}"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <b-col lg="4">
            <b-form-group>
                <label>Status <span class="text-danger mr5">*</span></label>
                <b-form-radio-group
                :options="Config.mr.StatusLaporan"
                v-model="row['lk_status']"
                />
            </b-form-group>
            </b-col>
        </div>


        <div class="heading_line mt-0">
            <h4>Foto Hasil Kunjungan</h4>
        </div>
        <div class="row mb-3" v-if="$parent.dataDok.length">
            <div class="col-md-4" v-for="(v,k) in $parent.dataDok" :key="k">
                <div class="wrap_doc_type">
                    <div class="btn_delete_wrap">
                        <button type="button" @click="removeRow(k)" class="btn btn-outline-danger-100 text-danger btn-icon"><i class="icon-trash"></i></button>
                    </div>
                    <div class="result_tab form-group ml-1">
                        <label>Foto #{{k+1}}<small class="txt_mandatory">*</small></label>
                        <Uploader v-model="$parent.dataDok[k]['lkd_file']" isDocument type="doc_umum"/>                   
                        <VValidate 
                            name="Foto" 
                            v-model="$parent.dataDok[k]['lkd_file']" 
                            :rules="{required:1}"
                        />          
                    </div>
                    <div>
                        <label for="">Remarks</label>
                        <textarea name="" id="" v-model="$parent.dataDok[k]['lkd_remarks']" rows="2" class="form-control" placeholder="e.g. Dokumen sudah terupdate"></textarea>
                    
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mb-3" v-else>
            <span class="mb-1 mt-3">Belum ada Foto Hasil Kunjungan</span>
            <button type="button" @click="addRow()" class="btn btn-outline alpha-purple text-purple-800 mt-4"><i class="icon-plus3"></i> Tambah Foto</button>
        </div>
        <button type="button" v-if="$parent.dataDok.length" @click="addRow()" class="btn btn-outline alpha-purple text-purple-800 mt-2"><i class="icon-plus3"></i> Tambah Foto</button>
      </b-card-body>
      <b-card-footer>
        <div class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
        </div>
      </b-card-footer>
    </b-form>
  </validation-observer>
  </template>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Datepicker from 'vuejs-datepicker'
import Gen from '@/libs/Gen.js'
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet"
import 'leaflet/dist/leaflet.css'
import { latLng, icon } from "leaflet"
export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15]
    }
  },
  mounted() {
    this.apiGet()
  },
  watch:{
    $route(){
    this.apiGet()
    }
  },
  data() {
    return {
      isOpen:false,
      srcFiles:"",
      arrFiles: [],

      zoom: 16,
      center: latLng(-6.3381733, 106.7410022),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      withTooltip: latLng(-6.3381733, 106.7410022),
      currentZoom: 16,
      currentCenter: latLng(-6.3381733, 106.7410022),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: false,

      icon: icon({
        iconUrl: "/marker-icon.png",
        iconSize: [20, 30],
        iconAnchor: [5, 18]
      }),
    }
  },
  components:{ 
    Datepicker,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  methods:{
    apiGet(params = {}){
        Gen.apiRest(
            "/get/"+this.$parent.modulePage+
            "/"+this.$parent.pageSlug, 
            {
            }
        ).then(res=>{
            console.log(+res.data.row.lk_lat, +res.data.row.lk_long)
            this.center = latLng(+res.data.row.lk_lat, +res.data.row.lk_long)
            this.withTooltip = latLng(+res.data.row.lk_lat, +res.data.row.lk_long)
            this.currentCenter = latLng(+res.data.row.lk_lat, +res.data.row.lk_long)
            this.showMap= true

        })
    },
    openPopup(files){
      this.arrFiles.push(files)
      this.srcFiles = this.arrFiles[0]
      window.open(this.$parent.uploader(this.srcFiles))
      setTimeout(()=>{
        this.srcFiles = ""
        this.arrFiles = []
      },500)
    },
    closePopup(){
      this.isOpen = false
      var el = document.getElementById("body-overlay")
      el.classList.remove("show")
    },
    addRow(){
        let payload = {
            lkd_lk_id: this.row.lk_id||"add", 
            lkd_file: "",  
            lkd_remarks: "",
        }
        this.$parent.dataDok.push(payload)
    },
    removeRow(k){
        this.$parent.dataDok.splice(k,1)
    },
    clearDebitur(){
        this.row.dp_bentuk_bu = null
        this.$parent.mrDebitur = []
    },
    searchDebitur(e){
        if(e.length > 2){
            Gen.apiRest('/do/BoLaporanKunjungan', {data: {type:'select-debitur', search: e, jenis: this.row.lk_jenis_debitur}}, 'POST').then(res=>{
                this.$parent.mrDebitur = res.data.data
            })
        }
    },
    searchLoan(){
        Gen.apiRest('/do/BoLaporanKunjungan', 
        {data: {type:'select-loan', search: this.row.lk_debitur_name, jenis: this.row.lk_jenis_debitur}}, 'POST').then(res=>{
            this.$parent.dataLoan = res.data.data
        })
    },
    changeDebitur(){
        this.row.lk_debitur_name = null
    },
    //
    zoomUpdate(zoom) {
      this.currentZoom = zoom
      console.log(this.currentZoom)
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    showLongText() {
      this.showParagraph = !this.showParagraph
    },
    //
    doSubmit(){
        this.row.dataDok = this.$parent.dataDok
      return this.$parent.doSubmit(
          "/do/BoLaporanKunjungan",
          _.assign({type:this.$parent.isAdd?'add':'update'}, {id: (this.$parent.pageId||this.$parent.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              if(this.isAccountSetting){
                location.reload()
              }else{
                this.$router.push({name:this.$route.name})
              }
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                    console.log(i)
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return this.$swal({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VForm', this.$refs
      )
    }
  }
}
</script>