<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Semua Jenis Kunjungan" @input="doFill" v-model="filter.type"
                      :options="Config.mr.JenisLaporan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Semua Type Debitur" @input="doFill" v-model="filter.typeDebitur"
                      :options="Config.mr.JenisDebitur" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Semua Status" @input="doFill" v-model="filter.status"
                      :options="Config.mr.StatusLaporan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table :fields="fields" :items="dataList||[]" :per-page="perPage" :primary-key="idKey" :busy="!dataList"
            responsive show-empty striped>
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                  <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(contact)="data">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span class="icon-user text-info"></span>
                </div>
                <div>
                  <a href="javascript:;"
                    class="text-default  letter-icon-title">{{data.item.lk_pengurus_fullname||"-"}}</a>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span class="icon-phone2 text-danger"></span>
                </div>
                <div>
                  <a href="javascript:;" class="text-default letter-icon-title">{{data.item.lk_kontak_telp||"-"}}</a>
                </div>
              </div>
            </template>
            <template #cell(number)="v">
                {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            <template #cell(lk_location)="data">
                {{data.value||"-"}}
            </template>
            
            <template #cell(lk_date)="data">
                {{data.value | moment("DD MMMM YYYY")}}
            </template>

            <template
                #cell(lk_status) = "data"
            >
                <span v-if="data.value=='I'" class="badge badge-warning">Incomplete</span>
                <span v-else class="badge badge-success">Completed</span>
            </template>
            <template
                #cell(action)="data"
            >
                <div class="list-icons">
                    <router-link :to="{name: $route.name, params: {pageSlug: data.item[idKey]}, query:{detail:true}}" data-toggle="modal" data-popup="tooltip"
                    title="View" class="list-icons-item"><i
                        class="icon-eye"></i></router-link>
                    <router-link class="list-icons-item"
                    v-if="moduleRole('edit')"
                    :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                    data-toggle="tooltip" data-placement="top" title="Edit"><i
                    class="icon-pencil5"></i></router-link>
                </div>
                <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
            <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
            />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div>
</template>

<script>
  import GlobalVue from '@/libs/Global.vue'
  import Form from './Form.vue'
  const _ = global._
  import Gen from '@/libs/Gen.js'


  export default {
    extends: GlobalVue,
    components: {
      Form
    },
    data() {
      return {
        idKey: 'lk_id',
        tabIndex: 0,
        fields: [{
            key: 'number',
            label: '#',
          },
          {
            key: 'bu_full_name',
            label: 'Surveyor/Visitor',
          },
          {
            key: 'lk_debitur_name',
            label: 'Nama Debitur',
          },
          {
            key: 'lk_location',
            label: 'Lokasi',
          },
          {
            key: 'lk_date',
            label: 'Tgl. Kunjungan',
          },
          {
            key: 'lk_status',
            label: 'Status',
          },
          {
            key: 'action',
            label: 'Aksi'
          },
        ],
        row: {},
        dataDok: [],
        mrDebitur: [],
        dataLoan: [],
        dataChunk: [],
        isDetail: false
      }
    },
    computed: {
      passToSub() {
        return _.assign(this.passToSubComp || {})
      }
    },
    mounted() {
      this.apiGet()
      if (this.$route.params.pageSlug) {
        this.validateModuleRoleCrud()
      }
    },
    methods: {
      openModalId(id) {
        this.loadingOverlay = true
        Gen.apiRest(
          "/get/" + this.modulePage + "/" + id, {}
        ).then(res => {
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          this.isDetail = true
          this.loadingOverlay = false
        })
      },
      doFill() {
        this.doFilter()
      },
      apiGet(params = {}){
        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
        if(params.slugs) slugs = params.slugs
        Gen.apiRest(
          "/get/"+this.modulePage+
          slugs, 
          {
            params: Object.assign({}, this.apiParams, params.query||{})
          }
        ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
          if(!this.isFound){
            this.$router.push({name : this.$route.name})
          }
        }).catch(()=>{
          this.loadingOverlay = false
        })
      },
    },
    watch:{
      $route(){
        this.apiGet()
      },
      '$route.params.pageSlug':function(){
        this.$set(this,'filter',{})
      }
    }
  }
</script>